document.querySelectorAll('[data-nav-handle]').forEach((el) => {
  const menu = document.getElementById(el.getAttribute('aria-describedby'));
  if (!menu) {
    console.warn('Element [data-nav-handle] must have valid aria-describedby');
    return false;
  }
  const html = document.documentElement;
  el.addEventListener('click', (evt) => {
    if ('true' !== el.getAttribute('aria-expanded')) {
      el.setAttribute('aria-expanded', 'true');
      menu.setAttribute('aria-expanded', 'true');
      html.classList.add('nav--opened');
    } else {
      el.setAttribute('aria-expanded', 'false');
      menu.setAttribute('aria-expanded', 'true');
      html.classList.remove('nav--opened');
    }
  });
});

document.querySelectorAll('.nav__submenu').forEach((submenu) => {
  const item = submenu.parentNode;
  const linkOpen = item.querySelector(':scope > .nav__opener');
  const linkClose = document.createElement('div');
  let parentMenu = item.closest('.nav__submenu');
  if (!parentMenu) {
    parentMenu = item.closest('.nav__menu');
  }
  linkClose.classList.add('nav__title');
  linkClose.innerHTML = linkOpen.innerHTML + item.querySelector(':scope > a').innerText;
  submenu.insertBefore(linkClose, submenu.firstChild);
  linkOpen.addEventListener('click', (evt) => {
    if ('true' !== item.getAttribute('aria-expanded')) {
      item.setAttribute('aria-expanded', 'true');
      parentMenu.classList.add('nav__covered');
    }
  });
  linkClose.addEventListener('click', (evt) => {
    if ('true' === item.getAttribute('aria-expanded')) {
      item.setAttribute('aria-expanded', 'false');
      parentMenu.classList.remove('nav__covered');
    }
  });
});

document.querySelectorAll('.nav__title').forEach((link) => {
  const item = link.closest('.nav__submenu').parentNode;

});