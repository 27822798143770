document.documentElement.classList.remove('no-js');

global.isVisible = function(el) {
  return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
}

global.respondToVisibility = function(element, callback) {
  const options = {
    root: document.documentElement
  }
  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      callback(entry.intersectionRatio > 0);
    });
  }, options);
  observer.observe(element);
}

global.getScrollbarWidth = function() {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  document.body.appendChild(outer);
  const widthNoScroll = outer.offsetWidth;
  outer.style.overflow = "scroll";
  const inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);
  const widthWithScroll = inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  return widthNoScroll - widthWithScroll;
}

let ww = window.innerWidth || document.documentElement.clientWidth,
  wh = window.innerHeight || document.documentElement.clientHeight,
  st = document.documentElement.scrollTop;

window.addEventListener('resize', function(){
  ww = window.innerWidth || document.documentElement.clientWidth
  wh = window.innerHeight || document.documentElement.clientHeight
  st = window.pageYOffset || document.documentElement.scrollTop
})
window.addEventListener('scroll', function(){
  st = document.documentElement.scrollTop
})

// blocks
import '../blocks/header/header';
import '../blocks/nav/nav';

// initialization functions
global.initJS = function(parent) {
//   initBanners(parent);
//   initSliders(parent);
}
initJS(document.body);

const scrollTop = document.getElementById('go-top');
window.addEventListener('scroll', () => {
  if (st >= 200 && !scrollTop.classList.contains('visible')) {
    scrollTop.classList.add('visible');
  } else if (st < 200 && scrollTop.classList.contains('visible')) {
    scrollTop.classList.remove('visible');
  }
});

document.body.addEventListener('click', (evt) => {
  const el = evt.target.closest('.js-scroll');
  if (!el) {
    return;
  }
  evt.preventDefault();
  const target = document.getElementById(el.getAttribute('href').slice(1));
  if (!target) {
    console.warn(`.js-scroll can't find target ${el.getAttribute('href')}`);
    return;
  }
  const offset = parseInt(target.getAttribute('data-scroll-offset'), 10) || 50;
  window.scrollTo({
    top: target.offsetTop - offset,
    behavior: 'smooth',
  });
});

// console.log(jQuery, window.jQuery);
import "../jquery.magnific-popup/jquery.magnific-popup.min"
window.jQuery('.wp-block-gallery').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled: true,
    preload: [
      0,
      2
    ]
  },
  // image: {
  //   titleSrc: 'data-caption'
  // },
  // mainClass: 'mfp-4'
});
